module.exports = {
  siteTitle: 'Landalytics - Landing pages built with a purpose.', // <title>
  manifestName: 'Landalytics',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Landalytics',
  subHeading:
    "Imagine having the ability to apply new models at the touch of a button to drive data backed marketing decisions.",
  socialLinks: [
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:garretthughes3@gmail.com',
    },
  ],
};
