import React, { Component } from "react";

export class EmailForm extends Component {
  constructor() {
    super();
    this.state = { message: "" };
  }

  componentDidMount() {
    if (typeof window !== "undefined" && window.location.search.includes("success=true")) {
      this.setState({ message: "You're officially on the waitlist!" });
      setTimeout(() => {
        this.setState({ message: "" });
      }, 3000);
    }
  }

  render() {
    const { message } = this.state;
    return (
      <form id="signup-form" method="post" name="waitlist" action="/?success=true" data-netlify="true">
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email Address"
          required
        />
        <input type="submit" value="Request access now" />
        <span className={`${message ? "visible success" : ""} message`}>
          {message}
        </span>
        <input type="hidden" name="form-name" value="waitlist" />
      </form>
    );
  }
}

export default EmailForm;
